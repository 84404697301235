import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyCLeJZct87pBkDUyhRWnw0eWMcJePC9UZM",
  authDomain: "my-littlefairy.firebaseapp.com",
  databaseURL: "https://my-littlefairy-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "my-littlefairy",
  storageBucket: "my-littlefairy.appspot.com",
  messagingSenderId: "79349043475",
  appId: "1:79349043475:web:d730067696510406494ae6",
  measurementId: "G-9GN1L8D8BQ"
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "MY. LITTLEFAIRY 水晶坊"; //公司名稱
      },
      get regFormDomain() {
        return "https://web.mylittlefairy.xyz/"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}